import React from "react";
import { useQuery } from "@tanstack/react-query";
import { filterparams } from "utils/common";
import { IFilterPagingParams, IUsersApiCall } from "utils/api.interfaces";

import axiosinterceptor from "../utils/api.interceptor";

const fetchUsers = (
  params: IFilterPagingParams
): Promise<IUsersApiCall> => {
  return axiosinterceptor
    .get(`/v1/Users`, {
      params: filterparams(params),
    })
    .then<IUsersApiCall>((res) => res.data);
};
export default function useUsers(params: IFilterPagingParams) {

  const querySearch = useQuery(
    ["users", params],
    () => fetchUsers(params),
    {
      keepPreviousData: true,
    }
  );

  return querySearch;
}
