import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IUser, IUserAdd, IUserEdit } from "utils/api.interfaces";

import axiosinterceptor from "../utils/api.interceptor";
import { skipEmpties } from "../utils/common";

export const fetchUser = (userId: string | undefined): Promise<IUser> =>
  axiosinterceptor.get(`/v1/Users/${userId}`).then<IUser>((res) => res.data);

export default function useUser(userId: string | undefined) {
  return useQuery(["user", userId], () => fetchUser(userId));
}
export const postImage = (image: File[]): Promise<any> => {
  const formData = new FormData();
  formData.append("imageFile", image[0]);

  return axiosinterceptor
    .post(`/v1/Users/ProfileImage`, formData)
    .then((res) => {
      return res.data;
    });
};
export const postUser = (user: IUserAdd): Promise<any> => {
  return axiosinterceptor.post(`/v1/Users`, skipEmpties(user)).then((res) => {
    return res.data;
  });
};
export const putUser = (user: IUserEdit, userId: string): Promise<any> => {
  return axiosinterceptor
    .put(`/v1/Users/${userId}`, skipEmpties(user))
    .then((res) => {
      return res.data;
    });
};
export const deleteUser = (userId: string): Promise<any> => {
  return axiosinterceptor
    .post(`/v1/Users/Remove`, { id: userId })
    .then((res) => {
      return res.data;
    });
};
