import { storage } from "utils/api.interfaces";
import axiosinterceptor from "../utils/api.interceptor";

export const logOut = async (): Promise<string> => {
  try {
    // Clear tokens first
    await storage.clearToken();

    // Return the loggedout URL
    return "/loggedout";
  } catch (error) {
    console.log("[Logout] Error during logout:", error);
    // If something goes wrong, still try to clear tokens and return loggedout URL
    await storage.clearToken();
    return "/loggedout";
  }
};

export const getVotesExport = (): Promise<any> => {
  return axiosinterceptor
    .get(`/v1/FootballMatches/votes/report`)
    .then((res) => {
      return res.data;
    });
};
