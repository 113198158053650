import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IActivities, IActivityAdd, IActivityEdit } from "utils/api.interfaces";

import axiosinterceptor from "../utils/api.interceptor";
import { skipEmpties } from "../utils/common";

export const fetchActivity = (
  activityId: string | undefined
): Promise<IActivities> =>
  axiosinterceptor
    .get(`/v1/Activity/${activityId}`, {})
    .then<IActivities>((res) => res.data);

export default function useActivity(activityId: string | undefined) {

  const queryActivity = useQuery(["activity", activityId], () =>
    fetchActivity(activityId)
  );

  return {
    ...queryActivity,
    dataActivity: queryActivity.data,
    dataActivityIsLoading: queryActivity.isLoading,
  };
}
export const postActivity = (activity: IActivityAdd): Promise<any> => {
  let activityFiltered = skipEmpties(activity);
  if (
    activityFiltered.content.nl.mediaContent?.type === "" ||
    activityFiltered.content.nl.mediaContent === ""
  ) {
    delete activityFiltered.content.nl.mediaContent;
  }
  if (
    activityFiltered.content.en.mediaContent?.type === "" ||
    activityFiltered.content.en.mediaContent === ""
  ) {
    delete activityFiltered.content.en.mediaContent;
  }
  if (!activityFiltered.content.nl.button?.text) {
    delete activityFiltered.content.nl.button;
  }
  if (!activityFiltered.content.en.button?.text) {
    delete activityFiltered.content.en.button;
  }

  return axiosinterceptor
    .post(`/v1/Activity`, activityFiltered, {})
    .then((res) => {
      return res.data;
    });
};
export const putActivity = (
  activityId: string | undefined,
  activityUpdate: IActivityEdit
): any => {
  let activityFiltered = skipEmpties(activityUpdate);

  if (activityFiltered.content.nl.mediaContent?.type === "") {
    delete activityFiltered.content.nl.mediaContent;
  }
  if (activityFiltered.content.en.mediaContent?.type === "") {
    delete activityFiltered.content.en.mediaContent;
  }
  if (!activityFiltered.content.nl.button?.text) {
    delete activityFiltered.content.nl.button;
  }
  if (!activityFiltered.content.en.button?.text) {
    delete activityFiltered.content.en.button;
  }

  return axiosinterceptor
    .put(`/v1/Activity/${activityId}`, activityFiltered, {})
    .then((res) => {
      return res;
    });
};
export const deleteActivity = (activityId: string): Promise<any> => {
  return axiosinterceptor
    .delete(`/v1/Activity/${activityId}`, {})
    .then((res) => {
      return res.data;
    });
};
export const postActivityImage = (image: File[]): Promise<any> => {
  const formData = new FormData();
  formData.append("imageFile", image[0]);

  return axiosinterceptor
    .post(`/v1/Activity/UploadImage`, formData)
    .then((res) => {
      return res.data;
    });
};
export const postActivityVideo = (video: File[]): Promise<any> => {
  const formData = new FormData();

  formData.append("VideoFile", video[0]);

  return axiosinterceptor
    .post(
      `https://intense-journey-25438.herokuapp.com/https://api-videoservice-tst.azurewebsites.net/api/v1/Video/UploadActivityVideo`,
      formData
    )
    .then((res) => {
      return res.data;
    });
};
