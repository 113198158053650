import axios, { AxiosError } from "axios";

import { storage } from "./api.interfaces";

const axiosinterceptor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Maximum number of refresh attempts to prevent infinite loops
const MAX_REFRESH_ATTEMPTS = 3;
let isRefreshing = false;

axiosinterceptor.interceptors.request.use(
  async (config) => {
    const token = await storage.getToken();
    if (config.headers) {
      config.headers["Authorization"] = `${token}`;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    console.log("[Token Refresh] Request interceptor error:", error);
    return Promise.reject(error);
  }
);

axiosinterceptor.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response?.status === 401 && !originalConfig._retry) {
      console.log("[Token Refresh] Starting refresh token process...");
      console.log("[Token Refresh] Failed request URL:", originalConfig.url);
      console.log(
        "[Token Refresh] Failed request method:",
        originalConfig.method
      );

      // Check if already refreshing
      if (isRefreshing) {
        console.log("[Token Refresh] Already refreshing token, waiting...");
        return new Promise((resolve) => {
          const interval = setInterval(async () => {
            if (!isRefreshing) {
              clearInterval(interval);
              const newToken = await storage.getToken();
              originalConfig.headers["Authorization"] = `${newToken}`;
              resolve(axiosinterceptor(originalConfig));
            }
          }, 100);
        });
      }

      // Check for maximum refresh attempts
      const refreshAttempts = (originalConfig._refreshAttempts || 0) + 1;
      originalConfig._refreshAttempts = refreshAttempts;

      if (refreshAttempts > MAX_REFRESH_ATTEMPTS) {
        console.log(
          "[Token Refresh] Maximum refresh attempts reached, logging out user"
        );
        await storage.clearToken();
        window.location.href = "/loggedout";
        return Promise.reject(err);
      }

      originalConfig._retry = true;
      isRefreshing = true;

      try {
        // Get the refresh token and current token
        const refreshToken = await storage.getRefreshToken();
        const currentToken = await storage.getToken();
        console.log("[Token Refresh] Current token exists:", !!currentToken);
        console.log("[Token Refresh] Refresh token exists:", !!refreshToken);
        console.log(
          "[Token Refresh] Refresh attempt:",
          refreshAttempts,
          "of",
          MAX_REFRESH_ATTEMPTS
        );

        if (!refreshToken) {
          console.log(
            "[Token Refresh] No refresh token available, logging out user"
          );
          await storage.clearToken();
          window.location.href = "/loggedout";
          return Promise.reject(err);
        }

        // Make a GET request using a separate axios instance
        console.log("[Token Refresh] Attempting to refresh token...");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/security/token`,
          {
            params: { refreshToken: refreshToken },
            headers: {
              Authorization: `${currentToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Validate response structure
        if (!response.data) {
          console.log(
            "[Token Refresh] Empty response from refresh token endpoint"
          );
          throw new Error("Empty response from refresh token endpoint");
        }

        // Check if we got a valid response with a code
        if (response.data && response.data.token) {
          console.log("[Token Refresh] Successfully received new token");
          console.log("[Token Refresh] Response data:", {
            hasToken: !!response.data.token,
            hasRefreshToken: !!response.data.refreshToken,
            tokenLength: response.data.token.length,
            refreshTokenLength: response.data.refreshToken?.length,
          });

          // Save the new token using the storage setToken method
          await storage.setToken("Bearer " + response.data.token);

          // Ensure refresh token is properly formatted before storing
          if (response.data.refreshToken) {
            const newRefreshToken = response.data.refreshToken;
            await storage.setRefreshToken(newRefreshToken);

            // Verify the refresh token was stored correctly
            const storedRefreshToken = await storage.getRefreshToken();
            console.log("[Token Refresh] Verification after storage:");
            console.log(
              "- Refresh token was updated:",
              storedRefreshToken !== refreshToken
            );
            console.log(
              "- Refresh token matches new:",
              storedRefreshToken === newRefreshToken
            );
          } else {
            console.log(
              "[Token Refresh] Warning: No new refresh token in response"
            );
          }

          // Update the Authorization header for the original request
          originalConfig.headers[
            "Authorization"
          ] = `Bearer ${response.data.token}`;

          console.log(
            "[Token Refresh] Retrying original request with new token"
          );
          // Retry the original request with the new token
          return axiosinterceptor(originalConfig);
        } else {
          console.log(
            "[Token Refresh] Invalid response from refresh token request",
            response.data
          );
          // If refresh token request doesn't return a valid token, logout user
          await storage.clearToken();
          window.location.href = "/loggedout";
          return Promise.reject(err);
        }
      } catch (_error) {
        const error = _error as AxiosError;
        console.log("[Token Refresh] Error during refresh:", {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          config: {
            url: error.config?.url,
            method: error.config?.method,
            params: error.config?.params,
          },
        });

        // Check if the error is due to an invalid refresh token
        if (error.response?.status === 400) {
          console.log(
            "[Token Refresh] Invalid refresh token, logging out user"
          );
          await storage.clearToken();
          window.location.href = "/loggedout";
          return Promise.reject(error);
        }

        // For other errors, still try to clear tokens and redirect
        await storage.clearToken();
        window.location.href = "/loggedout";
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }

    // Log other error responses for debugging
    if (err.response) {
      console.log("[Token Refresh] API Error Response:", {
        status: err.response.status,
        data: err.response.data,
        url: err.config?.url,
        method: err.config?.method,
      });
    }

    return Promise.reject(err);
  }
);

export default axiosinterceptor;
