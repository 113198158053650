import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { storage } from "utils/api.interfaces";

export default function LoggedIn() {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  // Use effect to handle async operations
  useEffect(() => {
    const handleTokens = async () => {
      // Handle tokens if present in URL
      if (searchParams.get("token")) {
        try {
          await storage.setToken(searchParams.get("token"));
          await storage.setRefreshToken(searchParams.get("refreshtoken"));
        } catch (error) {
          console.error("Failed to store tokens:", error);
        }
      }

      setIsLoading(false);

      // // Redirect to home page after a short delay
      // const timer = setTimeout(() => {
      //   window.location.href = "/";
      // }, 500000);

      // return () => clearTimeout(timer);
    };

    handleTokens();
  }, [searchParams]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      spacing={2}
      sx={{ width: "100%", height: "100vh" }}
    >
      <Typography variant="h1">You've successfully logged in!</Typography>
      {isLoading ? (
        <Typography variant="body1">Processing login...</Typography>
      ) : (
        <Button component="a" href="/" variant="contained" color="secondary">
          Click here to go to the homepage
        </Button>
      )}
    </Stack>
  );
}
