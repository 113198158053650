import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IUser } from "utils/api.interfaces";

import axiosinterceptor from "../utils/api.interceptor";
import { filterparams } from "../utils/common";

export const fetchUserPre = (userId: string | undefined): Promise<IUser> =>
  axiosinterceptor
    .get(`v1/Teams/preuser/`, {
      params: { Id: userId },
    })
    .then<IUser>((res) => res.data);

export default function useUserPre(userId: string | undefined) {
  return useQuery(["userPre", userId], () => fetchUserPre(userId));
}
