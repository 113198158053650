import React from "react";
import { useQuery } from "@tanstack/react-query";
import { filterOrganizationParams, filterparams } from "utils/common";
import {
  IOrganizarionsApiCall,
  IFilterPagingParams,
  IFilterOrganizationParams,
} from "utils/api.interfaces";

import axiosinterceptor from "../utils/api.interceptor";

const fetchOrganizations = (
  params: IFilterOrganizationParams
): Promise<IOrganizarionsApiCall> => {
  return axiosinterceptor
    .get(`/v1/Organizations`, {
      params: filterOrganizationParams(params),
    })
    .then<IOrganizarionsApiCall>((res) => res.data);
};
export default function useOrganizations(params: IFilterOrganizationParams) {
  const queryOrganizations = useQuery(
    ["organizations", params],
    () => fetchOrganizations(params),
    {
      keepPreviousData: true,
    }
  );

  return {
    ...queryOrganizations,
    dataOrganizations: queryOrganizations.data,
    organizationsIsLoading: queryOrganizations.isLoading,
  };
}
