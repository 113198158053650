import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import {
  OrganizationIcon,
  LeagueIcon,
  LogoMingle,
  LogoOldMingle,
  MatchIcon,
  TeamIcon,
  UserIcon,
} from "components/brandlibrary/MingleIcons";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box,
  Collapse,
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import GamesIcon from "@mui/icons-material/Games";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import BallotIcon from "@mui/icons-material/Ballot";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import AddSpeedDial from "./AddSpeedDial";
import { storage } from "utils/api.interfaces";
import { logOut } from "hooks/fetch";

const drawerWidth: number = 220;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    background: theme.palette.background.default,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),

      width: 52,
    }),
  },
}));

export const SideBar: React.FC<any> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [openTeamMenu, setOpenTeamMenu] = React.useState(false);
  const [openMatchMenu, setOpenMatchMenu] = React.useState(false);
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();


  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflow: "auto",
        minHeight: "calc(100vh )",
      }}
    >
      <AppBar
        position="absolute"
        open={open}
        color="secondary"
        sx={{
          color:
            process.env.REACT_APP_ENV === "PROD"
              ? theme.palette.success.main
              : "#fff",
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            justifyContent="start"
            alignContent="center"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {process.env.REACT_APP_ENV === "PROD" ? (
              <LogoMingle
                fontSize="large"
                color="primary"
                sx={{
                  color: theme.palette.success.main,
                  width: 50,
                  verticalAlign: "middle",
                  height: 56,
                }}
              />
            ) : (
              <LogoOldMingle
                fontSize="large"
                color="primary"
                sx={{
                  color: "#fff",
                  width: 50,
                  verticalAlign: "middle",
                  height: 56,
                  marginRight: "24px",
                }}
              />
            )}
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              Mingle Manager - {process.env.REACT_APP_ENV}
            </Typography>
            {/*<Typography*/}
            {/*  component="h1"*/}
            {/*  variant="h3"*/}
            {/*  color="inherit"*/}
            {/*  noWrap*/}
            {/*  sx={{*/}
            {/*    marginLeft: "auto!important",*/}
            {/*    paddingRight: "80px",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {pageHeader}*/}
            {/*</Typography>*/}

            {/*<Button*/}
            {/*  size="small"*/}
            {/*  variant="contained"*/}
            {/*  color="success"*/}
            {/*  onClick={() => navigate("/users/add")}*/}
            {/*  startIcon={<AddCircleIcon />}*/}
            {/*>*/}
            {/*  add a new user*/}
            {/*</Button>*/}
            <AddSpeedDial />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Tooltip title="Home" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/"
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Leagues" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/leagues"
          >
            <ListItemIcon>
              <LeagueIcon />
            </ListItemIcon>

            <ListItemText primary="Leagues" />
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Organizations" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/organizations"
          >
            <ListItemIcon>
              <OrganizationIcon />
            </ListItemIcon>

            <ListItemText primary="Organizations" />
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Users" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/users"
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Teams" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/teams"
          >
            <ListItemIcon>
              <TeamIcon />
            </ListItemIcon>
            <ListItemText primary="Teams" />
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Matches" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/match"
          >
            <ListItemIcon>
              <MatchIcon />
            </ListItemIcon>
            <ListItemText primary="Matches" />
          </ListItemButton>
        </Tooltip>

        <Divider />
        <Tooltip title="Activities" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/activity"
          >
            <ListItemIcon>
              <BallotIcon />
            </ListItemIcon>
            <ListItemText primary="Activities" />
          </ListItemButton>
        </Tooltip>
        <Tooltip title="MiniGames" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
            }}
            component={Link}
            to="/minigames"
          >
            <ListItemIcon>
              <GamesIcon />
            </ListItemIcon>

            <ListItemText primary="MiniGames" />
          </ListItemButton>
        </Tooltip>
        <Divider />

        <Tooltip title="Logout" arrow placement="right">
          <ListItemButton
            sx={{
              flexGrow: 0,
              marginTop: "auto",
            }}
            onClick={() => {
              logOut()
                .then((url) => (window.location.href = url))
                .catch((e) => {
                  storage.clearToken();
                  window.location.href = "/loggedout";
                });
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="log out" />
          </ListItemButton>
        </Tooltip>
        <ListItem component={Link} to="/brand">
          <ListItemIcon>
            <DesignServicesIcon />
          </ListItemIcon>
          <ListItemText primary="brand library" />
        </ListItem>
      </Drawer>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          marginTop: "84px",

          paddingBottom: "100px",
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
