import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IMatchAdd, IMatchEdit, IMatches } from "utils/api.interfaces";

import axiosinterceptor from "../utils/api.interceptor";

export const fetchMatch = (
  matchId: string | undefined
): Promise<IMatches> =>
  axiosinterceptor
    .get(`/v1/FootballMatches/${matchId}`)
    .then<IMatches>((res) => res.data);

export default function useMatch(matchId: string | undefined) {

  const queryTeam = useQuery(["match", matchId], () =>
    fetchMatch(matchId)
  );

  return {
    ...queryTeam,
    dataMatch: queryTeam.data,
    datamatchIsLoading: queryTeam.isLoading,
  };
}
export const postMatch = (match: IMatchAdd): Promise<any> => {
  return axiosinterceptor.post(`/v1/FootballMatches`, match).then((res) => {
    return res.data;
  });
};
export const putMatch = (matchUpdate: IMatchEdit): Promise<any> => {
  return axiosinterceptor
    .put(`/v2/FootballMatches/`, matchUpdate)
    .then((res) => {
      return res.data;
    });
};
export const deleteMatch = (matchId: string): Promise<any> => {
  return axiosinterceptor
    .delete(`/v1/FootballMatches/${matchId}`)
    .then((res) => {
      return res.data;
    });
};
