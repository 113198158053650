import React from "react";
import { useQuery } from "@tanstack/react-query";
import { filterActivityParams } from "utils/common";
import {
  IActivitiesApiCall,
  ISearchActivityParams,
} from "utils/api.interfaces";

import axiosinterceptor from "../utils/api.interceptor";

const fetchActivities = (
  params: ISearchActivityParams
): Promise<IActivitiesApiCall> => {
  return axiosinterceptor
    .get(`/v1/Activity`, {
      params: filterActivityParams(params),
    })
    .then<IActivitiesApiCall>((res) => res.data);
};
export default function useActivities(params: ISearchActivityParams) {

  const queryActivities = useQuery(
    ["activities", params],
    () => fetchActivities(params),
    {
      keepPreviousData: true,
    }
  );
  // if (queryActivities.data) {
  //   queryActivities.data.activities = queryActivities?.data?.activities.filter(
  //     (value) => !value.isRemoved
  //   );
  //   queryActivities.data.total = queryActivities.data.activities.length;
  // }
  return {
    // ...queryActivities,
    dataActivities: queryActivities.data,
    activitiesIsLoading: queryActivities.isLoading,
  };
}
